body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
td label {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropArea {
  cursor: pointer;
}

.entry-picking-select {
  font-size: 1.5rem;
}

.table.entry-picking-table.pack-admin{
  margin-left: -40px;
}

.table.entry-picking-table.pack-admin td{
  border: none;
  text-align: left;
}

.table.entry-picking-table.pack-admin td img{
  pointer-events: none
}

.table.entry-picking-table.pack-admin td:first-child{
  border-left: 1px solid #ddd;
}

.table.entry-picking-table.pack-admin td:nth-child(2){
  width: 5%;
}

.table.entry-picking-table.pack-admin td:nth-child(3){
  width: 15%;
}

.table.entry-picking-table.pack-admin td:nth-child(4){
  width: 25%;
}

.table.entry-picking-table.pack-admin td:nth-child(5), td:nth-child(6){
  width: 10%;
}

.table.entry-picking-table.pack-admin td:last-child{
  border-right: 1px solid #ddd;
}

.table.entry-picking-table.pack-admin tr {
  border: 1px solid #ddd
}

.table.entry-picking-table td{
  border: none;
  text-align: left;
}

.table.entry-picking-table td img{
  pointer-events: none
}

.table.entry-picking-table td:first-child{
  border-left: 1px solid #ddd;
}

.table.entry-picking-table td:nth-child(2){
  width: 5%;
}

.table.entry-picking-table td:nth-child(3){
  width: 15%;
}

.table.entry-picking-table td:nth-child(4){
  width: 25%;
}

.table.entry-picking-table td:nth-child(5), td:nth-child(6){
  width: 10%;
}

.table.entry-picking-table td:last-child{
  border-right: 1px solid #ddd;
}

.table.entry-picking-table tr {
  border: 1px solid #ddd
}


.entry-picking-select{
  display: flex;
  justify-content: center;
  
  
}

.entry-picking-select .form-control{
  width: 80%;
  appearance: auto;
  padding: 0.6rem
}

.entry-picking-select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../src/img/downArrow.svg');
  background-repeat: no-repeat;
  background-size: 0.9rem 0.9rem;
  background-position: calc(100% - 1rem);
}
