@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

body {
  margin: 0;
  font-family: "Muli", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiPagination-ul {
  justify-content: center;
}

code {
  font-family: "Muli", sans-serif !important;
}

h6,h5,h4, h3, h2, h1 {
  color: #595091;
}

.text-primary {
  color: #544f92 !important;
}

.border-primary {
  border-color: #544f92 !important;
}

.buttonGo {
  height: 30px;
  line-height: 10px;
}


.table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: white;
}

.table th {
  border: 1px solid #ddd;
}

.headData {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  align-items: center;
}

.tableList {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
}

.tableList td {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableList tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableList tr:hover {
  background-color: #ddd;
}

.tableList th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: white;
  color: white;
  font-weight: bold;
}
/* button {
  height: 31px;
  left: 870px;
  top: 248px;
  background: #575195;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  font-family: "Muli", sans-serif !important;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-style: normal;
} */

input {
  font-family: "Muli", sans-serif !important;
  font-style: normal;
  font-weight: 300;
}

.buttonBack {
  background: #ffffff;
  border: 0.5px solid #575195;
  box-sizing: border-box;
  border-radius: 1px;
  margin-bottom: 100px;
  color: #575195;
}

.divCenter {
  text-align: center;
  display: inline-block;
}
.buttonNext {
  box-shadow: "none";
}

.containerButtons {
  text-align: right;
  padding-top: 30px;
}

.containerButtons button:first-child {
  margin-right: 30px;
}

.containerButtons button:last-child {
  margin-right: 30px;
}

.principalButtons {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 235px;
  height: 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin-right: 112px;
  background-color: rgba(89, 80, 145, 0.6);
  margin-bottom: 30px;
}

#outer {
  width: 100%;
  text-align: center;
}
.inner {
  display: inline-block;
}

.containerPackingButtons {
  margin-bottom: 30px;
  width: 100%;
}

.containerPackingButtons h2 {
  font-weight: bold;
}


.dataList {
  border-collapse: collapse;
  /* width: 100%; */
  margin-bottom: 30px;
  margin-top: 30px;
  border: 0;
  font-size: 16px;
}

.selectedTableRow {
  background-color: #ddd;
}

.tableRow {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
}

.containerPackingTable {
  margin-bottom: 30px;
  display: inline-block;
}

.containerPackingTable button:last-child {
  width: 100px;
  margin-right: 30px;
  background: #ffffff;
  border: 0.5px solid #575195;
  box-sizing: border-box;
  border-radius: 1px;
  margin-bottom: 100px;
  float: right;
}

.buttonDisabled {
  background: rgba(89, 80, 145, 0.6);
}
.buttonEnabled {
  background: #595091;
}

.newPackButton {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 120px;
  height: 35px;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #33b5e5;
  position: absolute;
  margin-top: 50%;
}

.containerSearchOptions button:last-child {
  width: 220px;
  margin-right: 30px;
  display: block;
  font-size: 10px;
}
.containerSearchOptions button:first-child {
  width: 220px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 50px;
  display: block;
  font-size: 10px;
}

.searcher {
  position: absolute;
  width: 185px;
  height: 24px;
  left: 28px;
  top: 64px;
  background: #ffffff;
  border: 1px solid #575195;
  box-sizing: border-box;
  border-radius: 15px;
}
.inputContainer {
  display: block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  background: #ffffff;
  border: 1px solid #575195;
  box-sizing: border-box;
  border-radius: 15px;
  margin: auto;
  width: 60%;
}

.inputContainerSearch {
  display: block;
  position: absolute;
  background: #ffffff;
  border: 1px solid #575195;
  box-sizing: border-box;
  border-radius: 15px;
  margin-top: 15px;
  width: 100%;
}

.inputSearch {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: transparent;
  text-align: left;
  border: none;
  box-shadow: "none";
  outline-width: 0;
  padding-left: 10px;
}

.inputImgSearch {
  position: absolute;
  bottom: 4px;
  right: 5px;
  width: 14px;
  height: 14px;
}

.input {
  margin: auto;
  width: 100%;
  height: 20px;
  left: 46px;
  top: 64px;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: transparent;
  text-align: left;
  border: none;
  box-shadow: "none";
  outline-width: 0;
  padding-left: 10px;
}

.inputImg {
  position: absolute;
  bottom: 4px;
  right: 5px;
  width: 11px;
  height: 11px;
}

.navbarText{
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
}

.navbar-dropdown{
  margin-top: 1rem;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  box-shadow: none;
  font-weight: 600;
}

.navbar-selected-option{
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
}

.navbar-unselected-option{
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  color: #544F92;
}

.navbarGrid {
  /* position: fixed; */
  width: 100%;
  z-index: 1;
  background-color: white;
  display: grid;
  grid-template-columns: clamp(19rem, 19rem, 20rem) clamp(17rem, 17rem, 25rem) clamp(20rem, 20rem, 30rem) auto clamp(19rem, 19rem, 30rem);
  grid-template-rows: 12vh;
  grid-template-areas: "logo current selector step logout";
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.body-height {
  /* padding-top: 15vh; */
}

.tableDataSearcher {
  top: 60%;
  -ms-transform: translateY(60px);
  transform: translateY(60px);
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.tableDataSearcher td {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableDataSearcher tr:nth-child(even) {
  background-color: white;
}

.tableDataSearcher th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: white;
  color: white;
}

.containerTittleGrabber {
  display: inline-block;
  width: 100%;
  text-align: right;
}

.containerTittleGrabberImg {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-top: 20px;
}
.containerTittleGrabberTitte {
  width: 50px;
  border: none;
  font-size: 14px;
  padding-right: 0;
  float: right;
  clear: right;
  margin-top: 30px;
  color: #575195;
  font-weight: bold;
}

.containerTittleGrabberLabelName {
  width: 50px;
  border: none;
  font-size: 10px;
  padding-right: 0;
  float: right;
  clear: right;
  margin-top: 30px;
}
.containerTittleGrabberLabelClose {
  width: 35px;
  border-left: 1px solid #575195;
  box-shadow: none;
  font-size: 10px;

  float: right;
  clear: right;

  margin-top: 30px;
}

.containerTittleGrabberButton {
  width: 11px;
  height: 11px;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  font-size: 9px;
  float: right;
  clear: right;
  margin-top: 30px;
  padding-left: 3px;
  padding-bottom: 12px;
  margin-right: 0px;
}

.buttomPack {
  cursor: pointer;
}

.buttomPack:disabled {
  background: #c4c4c4;
}

.new-package-btn {
  color: #33b5e5;
  background: #ffffff;
  border: 1px solid #33b5e5;
  line-height: 25px;
}

.newPackButton {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 120px;
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #33b5e5;
  position: absolute;
  background: #ffffff;
  border: 1px solid #33b5e5;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 75px;
  padding-left: 10px;
}

.table-location td{
  vertical-align: middle;
}

.navbar-title {
  font-style: normal;
  font-weight: 600;
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 3.3rem;
  color: #595091;
}

.navbar-logo {
  width: 3.4rem;
}

.navbar-login{
  width: 100%;
  text-align: end;
}

.navbar-logout {
  border-left: 1px solid #575195;
  padding-left: 10px;
  padding-right: 30px;
  cursor: pointer;
}

.btn-logout {
  background: #575195;
  color: #ffffff;
  width: 18px;
  height: 18px;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  font-size: 12px;
  margin-top: 5px;
}

.containerTittle {
  display: inline-block;
  width: 100%;
}

.containerTittle img:nth-child(1) {
  width: 79px;
  height: 79px;
  display: inline-block;
  margin-right: 13px;
  margin-top: 30px;
}
.containerTittle label:nth-child(2) {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  display: inline-block;
  color: #595091;
}

.containerTittle label:nth-child(3) {
  width: 150px;
  border: none;
  font-size: 16px;
  padding-right: 10px;
  float: right;
  clear: right;
  margin-right: 100px;
  margin-top: 85px;
}

.containerTittle label:nth-child(4) {
  width: 100px;
  border-left: 1px solid #575195;
  box-shadow: none;
  font-size: 16px;
  padding-left: 10px;
  float: right;
  clear: right;
  padding-right: 30px;
  margin-top: -30px;
}

.containerTittle button:nth-child(5) {
  width: 18px;
  height: 18px;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  font-size: 12px;
  float: right;
  clear: right;
  margin-top: -30px;
  padding-left: 5px;
}

.title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #595091;
}

.subtitle {
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: #595091;
}

.documentData {
  font-size: 20px;
}

.newPackTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

.newPackTable td {
  border: 1px solid #ddd;
  padding: 8px;
}
.newPackTable td:nth-child(1) {
  width: 20%;
}
.newPackTable td:nth-child(2) {
  min-width: 10%;
  max-width: 15%;
}

.edit-bulk-input{
  width: 100%;
}


.newPackTable tr:nth-child(even) {
  background-color: white;
}

.newPackTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: white;
  color: white;
}

.card-label {
  width: 12cm;
  height: 7cm;
  page-break-after: always;
  border: 1px solid #000;
  display: inline-flex;
  margin: 20px;
  font-size: 12px;
}
.barcode {
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.containerBarcode {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
}

.barcode img {
  width: 200px;
  height: 80px;
}

.containerText {
  display: flex;
  flex: 1;
  position: relative;
}

.containerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.containerLogo img:nth-child(1) {
  width: 48px;
  height: 48px;
  display: inline-block;
  margin-right: 15px;
  text-align: right;
  margin-top: -216px;
}

.containerLogo label:nth-child(2) {
  position: absolute;
  display: inline-block;
  margin-right: 15px;
  text-align: right;
  margin-top: -70px;
  padding-right: 20px;
  font-size: 10px;
}
.cardText {
  width: 300px;
  height: 150px;
  
  padding-left: 5px;
  position: absolute;
  margin-top: 80px;
  font-size: 12px;
}

.card-bold {
  font-weight: bold;
}

.cardPack {
  border: solid black 1px;
  width: 100%;
  height: 60px;
  display: flex;
  margin-top: 10px;
  padding-left: 5px;
}
.cardPackDataLeft {
  width: 100px;
  height: 30px;
  position: absolute;
  display: inline-block;
  vertical-align: bottom;
  margin-top: 42px;
  font-size: 12px;
}
.cardPackDataRight {
  position: absolute;
  display: inline-block;
  vertical-align: bottom;
  margin-top: 42px;
  padding-left: 120px;
  font-size: 12px;
}

.cardBottomText {
  width: 100%;
  height: 20px;
  position: absolute;
  
  padding-left: 5px;
  font-size: 13px;
  margin-top: 5px;
}
.cardBottomText label:nth-child(1) {
  width: 100%;
  height: 20px;
  position: absolute;
  display: inline-block;
}
.cardBottomText label:nth-child(2) {
  width: 100%;
  height: 20px;
  position: absolute;
  display: inline-block;
  margin-left: 90px;
}
.cardBottomText label:nth-child(3) {
  width: 100%;
  height: 20px;
  position: absolute;
  display: inline-block;
  margin-left: 180px;
}
.tableListGrapper {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
  font-size: 10px;
}

.tableListGrapper td {
  border: 1px solid #ddd;
  padding: 8px;
}
.AcceptButtom {
  width: 100px;
  margin-right: 30px;
  color: white;
  background: #595091;
  border-radius: 3px;
  display: block;
  font-size: 14px;
}

.newPackTableResume {
  border-collapse: collapse;
  width: 300px;
  margin-bottom: 30px;
}

.newPackTableResume td {
  border: 1px solid #ddd;
  padding: 8px;
}

.newPackTableResume tr:nth-child(even) {
  background-color: white;
}

.newPackTableResume th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: white;
  color: white;
}
.searcherAdminContainer {
  top: 50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  background: #ffffff;
  border: 1px solid #575195;
  box-sizing: border-box;
  border-radius: 15px;
  margin: auto;
  width: 60%;
  margin-bottom: 50px;
}
.searcherAdminInput {
  margin: auto;
  width: 100%;
  height: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  left: 46px;
  top: 64px;
  background-color: transparent;
  text-align: left;
  border: none;
  box-shadow: "none";
  outline-width: 0;
}
.searcherAdminInputImg {
  position: absolute;
  bottom: 4px;
  right: 5px;
  width: 20px;
  height: 20px;
}
.adminButtom {
  min-width: 150px;
  max-width: 300px;
  margin-right: 30px;
  margin-top: 10px;
  float: right;
}
.containerAdmin {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  height: 65px;
}
.tableCalendar {
  margin-top: 20px;
  font-size: 16px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

.tableCalendar td {
  padding-left: 30px;
}

.select {
  width: 300px;
  height: 30px;

  font-size: 16px;
}
.inputCalendar {
  font-family: "Muli", sans-serif;
  width: 100%;
  font-size: 16px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.containerLogin {
  width: 120px;
  border-radius: 3px;
  font-size: 16px;
  position: fixed;
  top: 60%;
  left: 50%;
}

.containerLogin label:nth-child(1) {
  display: block;
  position: fixed;
  top: 22%;
  width: 30%;
  left: 40%;
}

.containerLogin input:nth-child(2) {
  display: block;
  position: fixed;
  top: 25%;
  width: 30%;
  left: 40%;
  border-radius: 3px;
  font-size: 16px;
}
.containerLogin label:nth-child(3) {
  top: 30%;
  width: 30%;
  left: 40%;
  display: block;
  position: fixed;
  font-size: 16px;
}
.containerLogin div:nth-child(4) {
  top: 33%;
  width: 30%;
  left: 40%;
  display: block;
  position: fixed;
  border-radius: 3px;
  font-size: 16px;
}
.containerLogin div:nth-child(4) input:nth-child(1){
  top: 33%;
  width: 30%;
  left: 40%;
  display: block;
  position: fixed;
  border-radius: 3px;
  font-size: 16px;
}
.containerLogin div:nth-child(4) img:nth-child(2){
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 5px;
}
.containerLogin button:nth-child(5) {
  width: 20%;
  border-radius: 3px;
  font-size: 16px;
  position: fixed;
  display: block;
  top: 40%;
  left: 45%;
  font-size: 16px;
}
.dataError {
  display: block;
  position: fixed;
  top: 15%;
  width: 30%;
  left: 40%;
  color: red;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.package-info {
  font-size: 12px;
  font-weight: 100;
}

.package-number {
  font-size: 16px;
  font-weight: bold;
}

.btn {
  font-weight: 600;
}

.show-print {
  display: none;
}

@page {
  margin: 0cm;
  padding: 0cm;
  size: 7cm 12cm portrait;
}

@media print {
  .show-print {
    display: block !important;
  }

  @page {
    margin: 0cm;
    padding: 0cm;
    size: 7cm 12cm portrait;
  }

  .hidden-print {
    display: none !important;
  }
  .container {
    min-width: unset;
  }

  .navbarGrid{
    display: none;
  }

  .card-label {
    width: 12cm;
    height: 7cm;
    page-break-after: always;
    border: 1px solid #000;
    display: inline-flex;
    transform: rotate(-90deg) translate(-12.4cm) scale(1);
    transform-origin: left top;
    margin: 0 0 5cm 3mm;
    font-size: 12px;
  }

  .card-container{
    width: 7cm !important;
    max-width: 0 !important;
  }

  .label-container {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.card-container{
  width: 7cm !important;
}

.loading-modal {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  justify-content: center;
  align-items: center;
}

.loading-modal figure {
  height: 100px;
  width: 100px;
}

@keyframes rotation-zoom { 
  0%   {transform: scale(1)  rotate(0deg);}
  25%  {transform: scale(1.5) rotate(120deg);}
  50%  {transform: scale(.5) rotate(240deg);}
  100% {transform: scale(1) rotate(360deg);}
}

.rotation-zoom {
  animation: rotation-zoom 2s infinite ease-in-out;
}

.no-border td{
  border: none;
}

.close-button{
  cursor: pointer;
}

.modal-footer{
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 20px;
  display: block;
  justify-content: space-evenly;
  border-top: 2px solid #ffffff !important;
}

.modal-body {
  border-top: 2px solid #595091 !important;
  border-top-width: 10ch;
  margin-left: 20px;
  margin-right: 60px;
  border-bottom: 0px solid #ffffff !important;
}

.modal-header {
  border-bottom: 0px solid #ffffff !important;
  border-bottom-width: 10ch;
}

.modal-subtitle{
  color: #212529 !important;
}

.navbarUserName {
  font-style: normal;
  font-size: 18px;
}

.navbarStrongText {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.nav-link{
  padding: 0.5rem 0;
}

.dropdown-menu {
  border: 0;
}

.center-landing {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row-landing{
  height: 10vh;
}

.button-landing {
  height: 100%;
  width: 38%;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 5rem;
  border-radius: 1rem;
}

div .navigationDropdown a#dropdownNavigationMenu{
  color: #544F92;
}

div .navigationDropdown a#dropdownNavigationMenu::after{
  transform: rotate(0deg);
}

div .navigationDropdown a#dropdownNavigationMenu.black-text{
  color: #212529;
}

div .navigationDropdown a#dropdownNavigationMenu.black-text::after{
  transform: rotate(180deg);
}

.printing {
  visibility:hidden;
}

.printView {
  visibility:visible;
}

.printing .printView {
  /* You can have any CSS here to make the view better on print */
  position:absolute;
  top:0;
}
